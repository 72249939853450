import useNotifier from 'hooks/useNotifier';

// TODO: MYR-2650
// think about use one useNotifier here and in useCreateResubscribeForm
const useFormErrorNotifier = () => {
  const { setError } = useNotifier();

  return () => {
    setError('Please make sure that all fields are filled in correctly');
  };
};

export default useFormErrorNotifier;
