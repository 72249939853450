import { useState, useEffect } from 'react';

import { SliderValue, SliderProps } from './types';
import { StyledSlider } from './styled';

const Slider = ({ value, onChange, size = 'small', ...props }: SliderProps) => {
  const [sliderValue, setSliderValue] = useState<SliderValue>(value ?? 0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (event: Event, newValue: SliderValue | number[], activeThumb: number) => {
    onChange?.(event, newValue as SliderValue);
    setSliderValue(newValue as SliderValue);
  };

  useEffect(() => {
    setSliderValue(value ?? 0);
  }, [value]);

  return <StyledSlider value={sliderValue} onChange={handleChange} aria-label="Slider" size={size} {...props} />;
};

export default Slider;
