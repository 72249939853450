import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';

const TypesQuery = loader('../../graphql/queries/enum.graphql');

const QUERY_NAME = '__type';

type EnumValue = {
  name: string;
  description: string;
};

type EnumType = {
  enumValues: EnumValue[];
} | null;

type EnumData = {
  [QUERY_NAME]: EnumType;
};

type Variables = {
  name: string;
};

export type ResultData = EnumValue[] | undefined;
export type ResultQuery = {
  data: ResultData;
  loading: boolean;
  error: ApolloError | undefined;
};

const getData = (data?: EnumData): ResultData => data?.[QUERY_NAME]?.enumValues;

const useTypeQueryHook = (variables: Variables): ResultQuery => {
  const { data, loading, error } = useQuery<EnumData, Variables>(TypesQuery, {
    variables,
    fetchPolicy: 'cache-first',
  });

  return { data: getData(data), loading, error };
};

export default useTypeQueryHook;
