import { useState, ChangeEvent } from 'react';
import { FormField, FormSectionProps } from 'components/shared/organisms/form/FormSection/types';

const getFilteredFormFields = (allFields: FormField[], hiddenFields: Record<string, any | never>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return allFields.reduce<FormField[]>((result, { name, isDefaultVisible, dependentFieldNames, ...rest }) => {
    const isFieldVisible = isDefaultVisible || hiddenFields?.[name];

    if (isFieldVisible) {
      result.push({
        name,
        ...rest,
      });
    }
    return result;
  }, []);
};

const getHiddenFields = (dependentFieldNames: string[], value: any) => {
  return dependentFieldNames.reduce<Record<string, any>>((reduceResult, dependentFieldName) => {
    reduceResult[dependentFieldName] = value;
    return reduceResult;
  }, {});
};

const useFormHiddenFields = (
  formFields: FormField[],
  handleChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
) => {
  const [hiddenFieldsStatus, setHiddenFieldsStatus] = useState({});

  const changeHandler: FormSectionProps['handleChange'] = (event) => {
    const { dependentFieldNames } = formFields.find((field) => field.name === event.target.name) ?? {};

    if (dependentFieldNames) {
      setHiddenFieldsStatus((prevState) => ({
        ...prevState,
        ...getHiddenFields(dependentFieldNames, event.target.value === 'true'),
      }));
    }
    if (handleChange) handleChange(event);
  };

  const visibleFormFields = getFilteredFormFields(formFields, hiddenFieldsStatus);

  return { changeHandler, visibleFormFields };
};

export default useFormHiddenFields;
