import { useRef } from 'react';
import { withTheme } from '@emotion/react';
import SignatureCanvas from 'react-signature-canvas';

import { OWNER } from 'config/constants/signatureHolder';

import FormHelperText from 'components/ui/atoms/FormHelperText';

import { signatureSize } from './signatureSize';
import { Wrapper, StyledIconButton, StyledTypography } from './styled';
import { SignatureProps } from './types';

const Signature = ({
  theme,
  name = 'signature',
  value,
  onChange,
  setSignature,
  width = signatureSize.width,
  height = signatureSize.height,
  helperText,
  error,
}: SignatureProps) => {
  const sigPad = useRef<any>(null);

  const clear = () => {
    sigPad.current.clear();

    onChange?.({ target: { value: null, name } });
    // TODO: MYR-2650
    // add ability to change not only OWNER
    setSignature?.(null, OWNER);
  };

  const trim = () => {
    const newSignature = sigPad.current.getTrimmedCanvas().toDataURL('image/png');

    onChange?.({ target: { value: newSignature, name } });
    setSignature?.(newSignature, OWNER);
  };

  return (
    <div>
      <Wrapper width={width} height={height}>
        {/* @ts-expect-error changing versions of react-signature-canvas, react, react-dom, typescript doesn't help */}
        <SignatureCanvas
          ref={sigPad}
          canvasProps={{ width: signatureSize.width, height: signatureSize.height }}
          penColor={theme.palette.text.primary}
          backgroundColor={theme.background.main}
          onEnd={trim}
        />
        {value && (
          <StyledIconButton onClick={clear}>
            <StyledTypography variant="t2">Clear</StyledTypography>
          </StyledIconButton>
        )}
      </Wrapper>
      {helperText && (
        <FormHelperText error={error ?? false}>
          <span>{helperText}</span>
        </FormHelperText>
      )}
    </div>
  );
};

export default withTheme(Signature);
