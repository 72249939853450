import { useMemo, useState, ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { FieldErrors } from 'types/fieldError';

import useValidation from 'lib/hooks/validation/useValidation';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import openThirdPartyLink from 'utils/openThirdPartyLink';

import { THANK_YOU_PAGE_URL } from 'config/constants/queryParams';

import FormSection from 'components/shared/organisms/form/FormSection';
import FormFooter, { Alignment } from 'components/shared/organisms/form/FormFooter';
import SubmitMessage from 'components/shared/organisms/form/SubmitMessage';
import Loader from 'components/ui/molecules/Loader';
import RedirectingMessage from 'components/ui/atoms/messages/RedirectingMessage';

import useSignature from './hooks/useSignature';
import useCreateResubscribeForm from './hooks/useCreateResubscribeForm';
import useFormErrorNotifier from './hooks/useFormErrorNotifier';
import {
  DEFAULT_VALIDATION_FIELDS,
  getResubscribeFields,
  DEFAULT_VALUES,
  AGREE_TEXT,
  ResubscribeFormValues,
} from './config';

const Form = () => {
  const [showRedirecting, setShowRedirecting] = useState(false);
  const [onCompleted, setOnCompleted] = useState<(() => void) | undefined>(undefined);
  const [apiErrors, setApiErrors] = useState<FieldErrors>({});
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSuccessSubmittedForm, setIsSuccessSubmittedForm] = useState(false);

  const [searchParams] = useSearchParams();
  const thankYouPage = searchParams.get(THANK_YOU_PAGE_URL);

  const setFormError = useFormErrorNotifier();

  const {
    autoValidation: autoRequiredValidation,
    fields,
    isValid,
    error: clientErrors,
    ...formProps
  } = useValidation({ fields: DEFAULT_VALUES }, true);

  const { setSignature, uploadSignature, validDraftOwnerSignature } = useSignature({ withPartner: false });

  const isMobile = useCheckMobileScreen();

  const formConfig = {
    columns: 1,
    withHiddenFields: false,
    formFields: getResubscribeFields(isMobile, setSignature),
  };

  const validSignature = Boolean((fields as ResubscribeFormValues).signature);
  const requiredValidationSchema = useMemo(() => Yup.object().shape(DEFAULT_VALIDATION_FIELDS), [fields]);

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (!isFormValuesChanged) setIsFormValuesChanged(true);
    autoRequiredValidation(requiredValidationSchema, name, value);
  };

  const { createForm, loading } = useCreateResubscribeForm({
    setIsSuccessSubmittedForm,
    onCompleted,
    uploadSignature,
    setApiErrors,
    setIsFormSubmitted,
  });

  const handleSubmit = async () => {
    setOnCompleted(() => {
      if (thankYouPage) {
        const { openLink, isSameDomainLink } = openThirdPartyLink(thankYouPage);
        openLink();
        if (isSameDomainLink) setShowRedirecting(true);
        else {
          setIsSuccessSubmittedForm(true);
          if (!isValid || !(validDraftOwnerSignature || validSignature)) {
            setFormError();
            // eslint-disable-next-line no-useless-return
            return;
          }
        }
      }
    });

    try {
      await createForm({ fields: fields as ResubscribeFormValues });
    } catch (creatingError) {
      console.error(creatingError);
    }
  };

  useEffect(() => {
    // @ts-expect-error remove after add types for useValidation
    if (!!fields.mobilePhone || !!fields.businessEmail) {
      setApiErrors({});
    }
    // @ts-expect-error remove after add types for useValidation
  }, [fields.mobilePhone, fields.businessEmail]);

  if (showRedirecting) {
    return (
      <Loader>
        <RedirectingMessage />
      </Loader>
    );
  }

  if (isSuccessSubmittedForm) return <SubmitMessage />;

  return (
    <form id="resubscribe-form">
      <FormSection
        validatedFields={fields}
        handleChange={handleChange}
        isFormValuesChanged={isFormValuesChanged}
        // TODO: MYR-2650
        // think about how to store errors in single state
        error={{ ...apiErrors, ...clientErrors }}
        isFormSubmitted={isFormSubmitted}
        {...formConfig}
        {...formProps}
      />
      <FormFooter
        disabled={loading || !isValid}
        submitHandler={handleSubmit}
        buttonConfirmationText={AGREE_TEXT}
        buttonText="Submit"
        footerAlignment={Alignment.Stretch}
      />
    </form>
  );
};

export default Form;
